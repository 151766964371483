import styled from 'styled-components';
import StyledButton from './StyledButton';
import { colors } from '../Assets/constants';

export default styled(StyledButton)`
  color: #fff;
  background-color: ${colors.deepBlue};
  border: ${colors.deepBlue} 1px solid;

  &:hover {
    background-color: ${colors.lightBlue};
    color: ${colors.deepBlue};
  }
`;