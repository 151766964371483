import styled from 'styled-components';
import StyledButton from './StyledButton';
import { colors } from '../Assets/constants';

export default styled(StyledButton)`
  color: ${colors.purple};
  border: ${colors.purple} 1px solid;

  &:hover {
    color: #000;
    border-color: #000;
  }
`;