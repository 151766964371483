import styled from 'styled-components';

const time = 1;

export default styled.a`
  display: inline-block;
  font-family: "Roboto";
  font-weight: normal;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  padding: 15px 50px;
  border-radius: 2px;
  transition: color ${time}s, background-color ${time}s, border-color ${time}s;
`;