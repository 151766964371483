export default [
  {
    id: 1,
    name: "Natalie1",
    role: "Organization",
    feedback: "I started here in March of last year and it was the best thing I’ve ever done. I had been to a couple different place before but nothing compares to Defiant.",
    rating: 5,
    image: 'feedback/image1.png',
  },
  {
    id: 2,
    name: "Brithany2",
    role: "Organization",
    feedback: "I started here in March of last year and it was the best thing I’ve ever done. I had been to a couple different place before but nothing compares to Defiant.",
    rating: 5,
    image: 'feedback/image2.png',
  },
  {
    id: 3,
    name: "Jonathan3",
    role: "Organization",
    feedback: "I started here in March of last year and it was the best thing I’ve ever done. I had been to a couple different place before but nothing compares to Defiant.",
    rating: 4,
    image: 'feedback/image3.png',
  },
  {
    id: 4,
    name: "Natalie4",
    role: "Organization",
    feedback: "I started here in March of last year and it was the best thing I’ve ever done. I had been to a couple different place before but nothing compares to Defiant.",
    rating: 5,
    image: 'feedback/image1.png',
  },
  {
    id: 5,
    name: "Brithany5",
    role: "Organization",
    feedback: "I started here in March of last year and it was the best thing I’ve ever done. I had been to a couple different place before but nothing compares to Defiant.",
    rating: 5,
    image: 'feedback/image2.png',
  },
  {
    id: 6,
    name: "Jonathan6",
    role: "Organization",
    feedback: "I started here in March of last year and it was the best thing I’ve ever done. I had been to a couple different place before but nothing compares to Defiant.",
    rating: 4,
    image: 'feedback/image3.png',
  },
  {
    id: 7,
    name: "Natalie7",
    role: "Organization",
    feedback: "I started here in March of last year and it was the best thing I’ve ever done. I had been to a couple different place before but nothing compares to Defiant.",
    rating: 5,
    image: 'feedback/image1.png',
  },
  {
    id: 8,
    name: "Brithany8",
    role: "Organization",
    feedback: "I started here in March of last year and it was the best thing I’ve ever done. I had been to a couple different place before but nothing compares to Defiant.",
    rating: 5,
    image: 'feedback/image2.png',
  },
  {
    id: 9,
    name: "Jonathan9",
    role: "Organization",
    feedback: "I started here in March of last year and it was the best thing I’ve ever done. I had been to a couple different place before but nothing compares to Defiant.",
    rating: 4,
    image: 'feedback/image3.png',
  },
  {
    id: 10,
    name: "Natalie10",
    role: "Organization",
    feedback: "I started here in March of last year and it was the best thing I’ve ever done. I had been to a couple different place before but nothing compares to Defiant.",
    rating: 5,
    image: 'feedback/image1.png',
  },
  {
    id: 11,
    name: "Brithany11",
    role: "Organization",
    feedback: "I started here in March of last year and it was the best thing I’ve ever done. I had been to a couple different place before but nothing compares to Defiant.",
    rating: 5,
    image: 'feedback/image2.png',
  },
  {
    id: 12,
    name: "Jonathan12",
    role: "Organization",
    feedback: "I started here in March of last year and it was the best thing I’ve ever done. I had been to a couple different place before but nothing compares to Defiant.",
    rating: 4,
    image: 'feedback/image3.png',
  },
];