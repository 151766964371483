import React, { useState } from 'react';
import StyledApp from './StyledApp';
import StyledLink from './Components/StyledLink';
import StyledPrimaryButton from './Components/StyledPrimaryButton';
import StyledSecondaryButton from './Components/StyledSecondaryButton';
import { ReactComponent as PeopleImage } from './Assets/images/people.svg';
import { ReactComponent as BuilderIcon } from './Assets/icons/builder.svg';
import { ReactComponent as MarketingIcon } from './Assets/icons/marketing.svg';
import { ReactComponent as FunnelIcon } from './Assets/icons/funnel.svg';
import { ReactComponent as DesignIcon } from './Assets/icons/design.svg';
import { ReactComponent as AnalyticIcon } from './Assets/icons/analytic.svg';
import { ReactComponent as TemplateIcon } from './Assets/icons/template.svg';
import { ReactComponent as BuildImage } from './Assets/images/info-build.svg';
import { ReactComponent as LaunchImage } from './Assets/images/info-launch.svg';
import { ReactComponent as TemplatesImage } from './Assets/images/info-templates.svg';
import { ReactComponent as SafaryIcon } from './Assets/icons/safary.svg';
import { ReactComponent as ChromeIcon } from './Assets/icons/chrome.svg';
import { ReactComponent as FirefoxIcon } from './Assets/icons/firefox.svg';
import { ReactComponent as EdgeIcon } from './Assets/icons/edge.svg';
import { ReactComponent as AppleIcon } from './Assets/icons/apple.svg';
import { ReactComponent as AndroidIcon } from './Assets/icons/android.svg';
import { ReactComponent as PlayIcon } from './Assets/icons/play.svg';
import { ReactComponent as FacebookIcon } from './Assets/icons/facebookIcon.svg';
import { ReactComponent as InstagramIcon } from './Assets/icons/instagramIcon.svg';
import { ReactComponent as TictokIcon } from './Assets/icons/tictokIcon.svg';
import { ReactComponent as TweeterIcon } from './Assets/icons/tweeterIcon.svg';
import { ReactComponent as YoutubeIcon } from './Assets/icons/youtubeIcon.svg';
import { ReactComponent as LeftArrow } from './Assets/icons/leftArrow.svg';
import { ReactComponent as RightArrow } from './Assets/icons/rightArrow.svg';
import { ReactComponent as Star } from './Assets/icons/star.svg';
import { ReactComponent as StarEmpty } from './Assets/icons/emptyStar.svg';
import { ReactComponent as BurgerIcon } from './Assets/icons/burgerMenu.svg';
import { ReactComponent as LogoTextIcon } from './Assets/icons/LogoAndText.svg';

import { APP_URL } from './Constants';

import reviews from './Components/mockedReviewers';


function App() {

  const actualWidth = document.documentElement.clientWidth;

  const [slide, setSlide] = useState(0);
  let slideSize = 400;
  let inSlider = 3;
  if (actualWidth <= 1200) {
    inSlider = 2;
  }
  if (actualWidth <= 960) {
    slideSize = 660;
    inSlider = 1;
  }
  if (actualWidth <= 640) {
    slideSize = 480;
  }
  if (actualWidth <= 480) {
    slideSize = 320;
  }

  console.log('TEST', actualWidth, inSlider);
  const slideStep = inSlider;
  const reviewsCount = reviews.length;

  const renderStars = (rate) => {
    let stars = [];
    for (let i = 1; i <= rate; i++) {
      stars.push(true);
    }
    for (let j = rate + 1; j <= 5; j++) {
      stars.push(false);
    }

    return stars.map( (star, index) => {
      return star ? <Star key={index} /> : <StarEmpty key={index}/>
    });
  }

  const handleLeftSlide = () => {
    if (slide >= slideStep) {
      setSlide(slide - slideStep);
    } else {
      setSlide(0);
    }
  };

  const handleRightSlide = () => {
    if (reviewsCount - slide - slideStep >= inSlider) {
      setSlide(slide + slideStep);
    }
  };

  const genaratePages = () => {
    let dots = [];
    const page = Math.ceil((slide + 1) / inSlider);
    for (let i = 1; i <= reviewsCount / inSlider; i++) {
      const dot = i === page ? <div key={i} className="dot active"></div> : <div  key={i} className="dot"></div>
      dots.push(dot);
    }

    return dots;
  }

  return (
    <StyledApp>
      <header className="shomio-header">
        <div className="mobile-menu">
          <BurgerIcon />
          <ul className="dropdown">
            <li className="mobile-item">
              <a href="/">Why mech</a>
            </li>
            <li className="mobile-item">
              <a href="/">Features</a>
            </li>
            <li className="mobile-item">
              <a href="/">Walkthrough</a>
            </li>
            <li className="mobile-item">
              <a href="/">Testimonials</a>
            </li>
            <li className="mobile-item">
              <a href="/">Pricing</a>
            </li>
          </ul>
        </div>
        <h1 className="title">
          <img src='MECH.png' alt="MECH" />
        </h1>
        <nav className="navigation">
          <a href="/">Why mech</a>
          <a href="/">Features</a>
          <a href="/">Walkthrough</a>
          <a href="/">Testimonials</a>
        </nav>
        <div className="space"></div>
        <StyledLink href={`${APP_URL}/console/login`} target="_blank">
          Log in
        </StyledLink>
        <StyledPrimaryButton href={`${APP_URL}/console/purchase`} target="_blank" className="start-button">
          Get Started
        </StyledPrimaryButton>
      </header>
      <section className="shomio-get-started">
        <aside className="left">
          <div className="image">
            <PeopleImage />
          </div>
        </aside>
        <aside className="right">
          <h2 className="title">
            Run More Effective Landiong Pages With Less Work
          </h2>
          <p className="info">
            Mech website builder makes it easy to create a website, blog, or sale page. Use Flexible customization or pre-build templated.
          </p>
          <div className="buttons-block">
            <StyledPrimaryButton href={`${APP_URL}/console/login`} target="_blank">
              Get Started
            </StyledPrimaryButton>
            <StyledSecondaryButton href="/">
              Watch a Demo
            </StyledSecondaryButton>
          </div>
        </aside>
      </section>
      <section className="shomio-why">
        <h2 className="title">Why Mech</h2>
        <p className="title-info">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className="block">
          <div className="item">
            <div className="icon">
              <BuilderIcon />
            </div>
            <h4 className="name">
              Intuitive Builder
            </h4>
            <p className="info">
              There are so many options to choose from when considering an exercise program. Learn about our most popular programs and choose which.
            </p>
          </div>
          <div className="item">
            <div className="icon">
              <MarketingIcon />
            </div>
            <h4 className="name">
              Marketing
            </h4>
            <p className="info">
              There are so many options to considering exercise program. Learn about our most popular programs and choose which.
            </p>
          </div>
          <div className="item">
            <div className="icon">
              <FunnelIcon />
            </div>
            <h4 className="name">
              Sale Funnels
            </h4>
            <p className="info">
              There are so many options to choose from when considering an exercise program. Learn about our most popular programs and choose which.
            </p>
          </div>
          <div className="item">
            <div className="icon">
              <DesignIcon />
            </div>
            <h4 className="name">
              Adaptive Design
            </h4>
            <p className="info">
              There are so many options to considering exercise program. Learn about our most popular programs and choose which.
            </p>
          </div>
          <div className="item">
            <div className="icon">
              <AnalyticIcon />
            </div>
            <h4 className="name">
              Analytics
            </h4>
            <p className="info">
              There are so many options to choose from when considering an exercise program. Learn about our most popular programs and choose which.
            </p>
          </div>
          <div className="item">
            <div className="icon">
              <TemplateIcon />
            </div>
            <h4 className="name">
              Templates
            </h4>
            <p className="info">
              There are so many options to considering exercise program. Learn about our most popular programs and choose which.
            </p>
          </div>
        </div>
      </section>
      <section className="shomio-info">
        <aside className="content">
          <div className="row">
            <div className="info-box">
              <h3 className="name">Templates Library</h3>
              <p className="description">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummywhen an unknown printer took a galley of type and scrambled it to make a type specimen book.
                It has survived not only five centuries.
              </p>
            </div>
            <div className="image-box">
              <div className="image-right">
                <TemplatesImage />
              </div>
            </div>
          </div>
          <div className="row left">
            <div className="image-box">
              <div className="image-left">
                <BuildImage />
              </div>
            </div>
            <div className="info-box">
              <h3 className="name">Easy to Build</h3>
              <p className="description">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum  standard dummy text ever since the 1500s, 
                when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="info-box">
              <h3 className="name">Quick Launch</h3>
              <p className="description">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum  standard dummy text ever since the 1500s, 
                when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.
              </p>
            </div>
            <div className="image-box">
              <div className="image-right">
                <LaunchImage />
              </div>
            </div>
          </div>
        </aside>
      </section>
      <section className="shomio-audience">
        <h2 className="title">
          Your Audience Can Join From Anywhere
        </h2>
        <p className="info">
          A hassle-free, no-download experience for everyone.
        </p>
        <div className="icons-block">
          <div className="icon">
            <SafaryIcon />
          </div>
          <div className="icon">
            <AppleIcon />
          </div>
          <div className="icon">
            <FirefoxIcon />
          </div>
          <div className="icon">
            <AppleIcon />
          </div>
          <div className="icon">
            <AppleIcon />
          </div>
          <div className="icon">
            <AndroidIcon />
          </div>
        </div>
      </section>
      <section className="shomio-video">
        <div className="placeholder">
          <div className="play-icon">
            <PlayIcon />
          </div>
          <h2 className="title">
            It's a modern Website Builder
          </h2>
          <p className="description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
      </section>
      <section className="shomio-customers">
        <h2 className="title">
          Our Customers
        </h2>
        <p className="description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className="carousel-block">
          <div className="carousel">
            <div className="arrow" onClick={() => handleLeftSlide()}>
              <LeftArrow />
            </div>
            <div className="wraper">
              <div className="items" style={{left: `-${slide * slideSize}px`}}>
                {reviews.map(review => (
                  <div className="review" key={review.id}>
                    <div className="photo">
                      <img src={review.image} />
                    </div>
                    <h4 className="name">{review.name}</h4>
                    <h5 className="role">{review.role}</h5>
                    <p className="text">
                      {review.feedback}
                    </p>
                    <div className="stars">
                      { renderStars(review.rating) }
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="arrow" onClick={() => handleRightSlide()}>
              <RightArrow />
            </div>
          </div>
          <div className="dots">
            { genaratePages() }
          </div>
        </div>
      </section>
      <section className="shomio-ready">
        <h2 className="title">
          Ready To Grow Your Business With Mech?
        </h2>
        <p className="description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </p>
        <StyledPrimaryButton  href={`${APP_URL}/console/purchase`} target="_blank">
          Get Started
        </StyledPrimaryButton>
      </section>
      <footer className="shomio-footer">
        <h1 className="title">
          <img src='MECH.png' alt="MECH" />
        </h1>
        <nav className="navigation">
          <a href="/">Why mech</a>
          <a href="/">Features</a>
          <a href="/">Walkthrough</a>
          <a href="/">Testimonials</a>
          <a href="/">Pricing</a>
        </nav>
        <div className="space"></div>
        <div className="icons-block">
          <div className="icon">
            <FacebookIcon />
          </div>
          <div className="icon">
            <InstagramIcon />
          </div>
          <div className="icon">
            <YoutubeIcon />
          </div>
          <div className="icon">
            <TweeterIcon />
          </div>
          <div className="icon">
            <TictokIcon />
          </div>
        </div>
        <div className="copyright">
          &copy;2021&nbsp;&nbsp;Copyright
        </div>
      </footer>
    </StyledApp>
  );
}

export default App;
