export const colors = {
  deepBlue: "#1790FF",
  purple: "#FE8A8F",
  dark: "#303958",
  dark4: "#404040",
  dark6: "#666",
  lightBlue: "#EAF5FF",
  grey: "#D8D8D8",
  grey2: "#B7BCC5",
  grey3: "#F8F8FA",
  grey4: "#AFB1BB",
};

export const sizes = {
  contentWidth: 1200,
}