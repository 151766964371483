import styled from 'styled-components';
import { colors, sizes } from './Assets/constants';

export default styled.main`
  margin: 0;
  padding: 0;

  .shomio-header {
    padding: 30px;
    display: grid;
    align-items: center;
    grid-template-columns: 150px 450px auto 100px 200px;

    .mobile-menu {
      display: none;
    }

    .title {
      margin: 0;
      padding: 0;
      font-family: "Ropa";
      color: ${colors.dark};
      font-size: 34px;

      >svg {
        width: 100%;
      }

      >img {
        width: 100%;
      }
    }

    .navigation {
      a {
        font-family: "Roboto";
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        text-decoration: none;
        color: ${colors.dark};
        transition: color .5s;
        display: inline-block;
        padding: 0 13px;

        &:hover {
          color: ${colors.deepBlue};
        }
      }
    }
  }

  .shomio-get-started {
    margin: 0 auto;
    min-height: 730px;
    display: grid;
    grid-template-columns: 50% 50%;
    background: ${colors.lightBlue};
    font-family: "Roboto";
    font-weight: 400;
    font-weight: normal;

    .left {
      position: relative;

      .image {
        z-index: 1;
        position: absolute;
        right: 85px;
        top: 55px;
      }
    }

    .right {

      z-index: 2;
      .title {
        font-size: 48px;
        color: ${colors.dark};
        font-weight: 400;
        margin: 220px 0 10px 0;
        max-width: ${sizes.contentWidth / 2}px;
      }

      .info {
        color: ${colors.dark};
        max-width: 550px;
        font-size: 16px;
        line-height: 24px;
      }

      .buttons-block {

        > a {
          &:first-of-type {
            margin-right: 20px;
          }
        }
      }
    }
  }

  .shomio-why {
    margin: 0 auto;
    padding: 160px 0 180px 0;
    text-align: center;
    font-family: "Roboto";
    font-weight: 400;
    font-style: normal;
    border-bottom: ${colors.grey} 1px solid;

    .title {
      font-size: 36px;
      font-weight: 400;
      margin: 0 0 20px 0;
      color: ${colors.dark};
    }

    .title-info {
      font-size: 16px;
      max-width: 500px;
      margin: 0 auto;
      color: ${colors.dark4};
    }

    .block {
      max-width: ${sizes.contentWidth}px;
      margin: 80px auto 180px auto;
      display: grid;
      align-items: center;
      grid-template-columns: 33.3% 33.3% 33.3%;
      grid-template-rows: 50% 50%;

      .item {
        padding: 10px;

        .name {
          font-weight: 400;
          color: ${colors.dark};
          font-size: 24px;
        }

        .info {
          padding: 0;
          margin: 20px 0 0 0;
          font-size: 16px;
          color: ${colors.dark6};
        }
      }
    }
  }

  .shomio-info {
    font-family: "Roboto";
    font-weight: 400;
    font-style: normal;
    border-bottom: ${colors.grey} 1px solid;

    .content {
      margin: 150px auto 50px auto;
      max-width: ${sizes.contentWidth}px;

      .row {
        display: flex;
        min-height: 500px;
      }

      .name {
        margin: 100px 0 20px 0;
        padding: 0;
        font-weight: 400;
        color: ${colors.dark};
        font-size: 36px;
      }

      .description {
        margin: 0;
        padding: 0;
        color: ${colors.dark4};
        /* max-width: 500px; */
        font-size: 16px;
      }
    }

    .image-box {

      .image-right {
        margin-left: 40px;
      }

      .image-left {
        margin-right: 40px;
      }
    }
  }

  .shomio-audience {
    font-family: "Roboto";
    text-align: center;

    .title {
      font-weight: 400;
      font-style: normal;
      font-size: 36px;
      margin: 160px 0 10px 0;
      color: ${colors.dark};
    }

    .info {
      padding: 0;
      margin: 0 0 25px 0;
      font-weight: 400;
      font-style: normal;
      color: ${colors.dark4};
      font-size: 16px;
    }

    .icons-block {
      display: grid;
      grid-template-columns: 16.66% 16.66% 16.66% 16.66% 16.66% 16.66%;
      align-items: center;
      justify-content: space-between;
      max-width: 500px;
      margin: 0 auto 160px auto;
    }
  }

  .shomio-video {
    height: 620px;

    .placeholder {
      height: 100%;
      margin: 0;
      padding: 0;
      background: #ddd;
      text-align: center;
      font-family: "Roboto";
      font-weight: 400;
      font-style: normal;

      .play-icon {
        padding: 190px 0 0 0;
        cursor: pointer;
      }

      .title {
        font-weight: 400;
        font-style: normal;
        font-size: 36px;
        margin: 30px 0 15px 0;
        color: ${colors.dark};
      }

      .description {
        font-size: 16px;
        padding: 0;
        max-width: 550px;
        margin: 0 auto;
        color: ${colors.dark4};
      }
    }
  }

  .shomio-ready {
    background: ${colors.lightBlue};
    text-align: center;
    font-family: "Roboto";
    font-weight: 400;
    font-style: normal;
    padding: 160px 10px;

    .title {
      font-weight: 400;
      font-style: normal;
      font-size: 36px;
      margin: 0 0 15px 0;
      color: ${colors.dark};
    }

    .description {
      font-size: 16px;
      padding: 0;
      max-width: 550px;
      margin: 0 auto 25px auto;
      color: ${colors.dark4};
    }
  }

  .shomio-customers {
    margin: 0;
    padding: 180px 0;
    text-align: center;
    font-family: "Roboto";
    font-weight: 400;
    font-style: normal;

    .title {
      font-weight: 400;
      font-style: normal;
      font-size: 36px;
      margin: 0 0 20px 0;
      padding: 0;
      color: ${colors.dark};
    }

    .description {
      color: ${colors.dark4};
      font-size: 16px;
      margin: 0 auto 70px auto;
      max-width: 550px;
    }

    .carousel-block {

      .carousel {
        display: flex;
        max-width: 1240px;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;

        .arrow {
          cursor: pointer;

          >svg {
            >path {
              transition: fill 0.5s;
            }
          }

          &:hover {
            >svg {
              >path {
                fill: ${colors.deepBlue};
              }
            }
          }
        }

        .wraper {
          position: relative;
          width: 1200px;
          height: 360px;
          overflow: hidden;
        }

        .items {
          display: flex;
          justify-content: space-around;
          background: #fff;
          position: absolute;
          top:1;
          transition: left 1s;

          .review {
            width: 360px;
            height: 360px;
            background: ${colors.grey3};
            margin: 0 20px;

            .photo {
              width: 80px;
              height: 80px;
              margin: 30px auto 0 auto;
              overflow: hidden;
              border-radius: 50%;

              >img {
                width: 100%;
              }
            }

            .name {
              margin: 10px 0 0 0;
              font-size: 16px;
              font-weight: normal;
            }

            .role {
              margin: 10px 0 0 0;
              font-weight: normal;
              font-size: 14px;
              color: ${colors.grey4};
            }

            .text {
              margin: 0;
              padding: 15px;
              font-size: 16px;
              line-height: 150%;
              color: ${colors.dark4};
            }

            .stars {
              width: 130px;
              margin: 0 auto;
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }


    .dots {
      margin: 20px auto 0 auto;
      display: flex;
      justify-content: center;

      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: ${colors.grey4};
        margin: 0 2px;

        &.active {
          background: ${colors.deepBlue};
        }
      }
    }
  }

  .shomio-footer {
    text-align: center;
    padding: 80px 0 0 0;

    .title {
      margin: 0 0 30px 0;
      padding: 0;
      font-family: "Ropa";
      color: ${colors.dark};
      font-size: 40px;

      >img {
        width: 220px;
      }
    }

    .navigation {
      a {
        font-family: "Roboto";
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        text-decoration: none;
        color: ${colors.dark};
        transition: color .5s;
        display: inline-block;
        padding: 0 13px;

        &:hover {
          color: ${colors.deepBlue};
        }
      }
    }

    .space {
      height: 0;
      width: 70px;
      margin: 50px auto;
      border-bottom: 1px solid ${colors.grey};
    }

    .icons-block {
      margin: 0 auto 80px auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 200px;

      .icon {
        cursor: pointer;

        >svg {
          >rect, >path {
            transition: fill 0.5s;
          }
        }

        &:hover {
          >svg {
            > rect {
              fill: ${colors.deepBlue};
            }
            > path {
              fill: ${colors.deepBlue};
            }
          }
        }
      }
    }

    .copyright {
      background: ${colors.grey3};
      color: ${colors.grey2};
      font-size: 12px;
      margin: 0;
      padding: 16px 0;
    }
  }

  @media (max-width: 1280px) {
    .shomio-get-started {

      .right {
        padding: 0 60px 0 0;

        .title {
          margin: 180px 0 10px 0;
        }
      }
    }

    .shomio-customers {

      .carousel-block {
         
        .carousel {

          .wraper {
            width: 1160px;
          }
          
          .items {

            .review {
              margin: 0 40px 0 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .shomio-info {

      .content {
        padding: 0 20px;

        .row {

          .image-box {

            .image-right, .image-left {
              width: 540px;

              >svg {
                width: 100%;
              }
            }

            .image-right {
              margin-left: 20px;
            }

            .image-left {
              margin-right: 20px;
            }
          }
        }
      }
    }

    .shomio-customers {

      .description {
        padding: 0 10px;
      }

      .carousel-block {
   
        .carousel {
          max-width: 840px;

          .wraper {
            width: 760px;
          }
        }
      }
    }
  }

  @media (max-width: 960px) {
    .shomio-header {
      grid-template-columns: 60px 110px auto 100px 200px;
      .mobile-menu {
        display: block;
        cursor: pointer;
        position: relative;
        z-index: 2;

        >svg {
          cursor: pointer;
        }

        &:hover {
          .dropdown {
            z-index: 3;
            position: absolute;
            display: block;
            left: 15px;
            top: 15px;
            box-shadow: 0px 0px 50px rgba(14, 25, 41, 0.1);
            background-color: #FFF;
            margin: 0;
            padding: 10px;
            list-style: none;

            .mobile-item {
              padding: 10px 15px;

              >a {
                font-family: "Roboto";
                font-weight: 400;
                font-style: normal;
                font-size: 16px;
                text-decoration: none;
                color: ${colors.dark};
                transition: color .5s;

                &:hover {
                  color: ${colors.deepBlue};
                }
              }
              
            }
          }
        }

        .dropdown {
          display: none;
        }
      }

      .navigation {
        display: none;
      }
    }

    .shomio-get-started {
      grid-template-columns: 100%;

      .left {
        order: 2;

        .image {
          width: 100%;
          position: static;
          margin-bottom: -50px;

          >svg {
            width: 100%;
            height: auto;
          }
        }
      }

      .right {
        order: 1;
        margin: 0 auto;
        max-width: 620px;
        padding: 0;

        .title {
          margin-top: 120px;
        }

        .buttons-block {
          margin-bottom: 50px;
        }
      }
    }

    .shomio-why {

      .block {
        grid-template-columns: 50% 50%;
        grid-template-rows: 33.3% 33.3% 33.3%;
        padding: 0 20px;

        .item {
          margin-top: 70px;
        }
      }
    }

    .shomio-info {

      .content {

        .row {
          display: block;

          .image-box {

            .image-right, .image-left {
              margin: 0 auto;
              width: 100%;
            }
          }

          &.left {
            display: flex;
            flex-direction: column-reverse;
          }
        }
      }
    }

    .shomio-customers {

      .carousel-block {

        .carousel {
          max-width: 680px;

          .wraper {
            width: 620px;
          }

          .items {
            .review {
              width: 620px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 640px) {
    .shomio-header {
      padding: 30px 10px;
      grid-template-columns: 55px 100px auto 90px 190px;
    }

    .shomio-get-started {

    .right {
      padding: 0 20px;
    }
  }

    .shomio-why {
      padding: 160px 10px 180px 10px;

      .block {
        grid-template-columns: 100%;
        grid-template-rows: auto;
        padding: 0 20px;
        margin-bottom: 0;

        .item {
          margin: 70px auto 0 auto;
          max-width: 300px;
        }
      }
    }

    .shomio-audience {
      margin: 0 auto;
      padding: 0 10px;
      max-width: 460px;

      .icons-block {
        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;
        grid-template-rows: 50%;
        width: 300px;
        height: 200px;
        margin: 0 auto 160px auto;
      }
    }

    .shomio-video {

      .placeholder {

        padding: 0 10px;

        .title, .description {
          max-width: 460px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .shomio-customers {

      .carousel-block {

        .carousel {
          max-width: 100%;

          .wraper {
            width: 440px;
          }

          .items {
            .review {
              width: 440px;
            }
          }
        }
      }
    }

    .shomio-ready {

      .title, .description {
        max-width: 460px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @media(max-width: 480px) {
    .shomio-header {
      grid-template-columns: 55px 100px auto 0 130px;

      .start-button {
        padding: 15px 20px;
      }
    }

    .shomio-get-started {

      .right {

        .buttons-block {
          >a {
            display: block;
            margin: 10px auto;

            &:first-of-type {
              margin-right: auto;
            }
          }
        }
      }
    }

    .shomio-customers {

      .carousel-block {

        .carousel {

          .wraper {
            width: 280px;
          }

          .items {
            .review {
              width: 280px;
            }
          }
        }
      }
    }
  }
`;