import styled from 'styled-components';
import StyledButton from './StyledButton';
import { colors } from '../Assets/constants';

export default styled(StyledButton)`
  padding: 15px;
  color: ${colors.dark};

  &:hover {
    color: ${colors.deepBlue};
  }
`;